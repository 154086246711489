
riot.tag2('facebook-script', '', '', '', function(opts) {
    !function(d, s, id){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (!d.getElementById(id)){
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v2.4";
        fjs.parentNode.insertBefore(js,fjs);
      }
    }(document, 'script', 'facebook-jssdk');

});
riot.tag2('facebook', '<facebook-script></facebook-script> <div> <div data-href="{opts.page}" data-small-header="{fb.smallheader}" data-adapt-container-width="{adapt}" data-hide-cover="{fb.hidecover}" data-show-facepile="{fb.facepile}" data-show-posts="{fb.showposts}" data-width="{width}" data-height="{opts.height}" class="fb-page"> <div class="fb-xfbml-parse-ignore"> <blockquote cite="{opts.page}"><a href="{opts.page}">{opts.name}</a></blockquote> </div> </div> </div>', '', '', function(opts) {
this.resizing = null;

this.show = true;

this.adapt = 'true';

this.on('mount', (function(_this) {
  return function() {
    _this.width = Math.floor(_this.root.getBoundingClientRect().width);
    window.addEventListener('resize', _this.resize);
    _this.fb = {};
    _this.fb.smallheader = opts.options.match(/smallheader/) != null;
    _this.fb.hidecover = opts.options.match(/hidecover/) != null;
    _this.fb.facepile = opts.options.match(/facepile/) != null;
    _this.fb.showposts = opts.options.match(/showposts/) != null;
    return _this.update();
  };
})(this));

this.on('unmount', function() {
  return window.removeEventListener('resize', this.resize);
});

this.resize = (function(_this) {
  return function() {
    clearTimeout(_this.resizing);
    return _this.resizing = setTimeout(function() {
      var ref;
      _this.width = Math.floor(_this.root.getBoundingClientRect().width);
      _this.adapt = 'false';
      _this.update();
      return typeof FB !== "undefined" && FB !== null ? (ref = FB.XFBML) != null ? ref.parse() : void 0 : void 0;
    }, 500);
  };
})(this);
});