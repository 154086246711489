require('./raw')
riot.tag2('ticker', '<div class="ticker-title">Latest: </div> <div class="ticker-editor {editmode:opts.editmode}"><yield></yield></div> <div class="ticker-content {editmode:opts.editmode}"> <tick each="{tick in ticksContent}"> <raw content="{tick}"></raw> </tick> </div> <div class="ticker-controls"> <div show="{!ticker_interval}" onclick="{play}" class="ticker-controls_play"><i class="fa fa-play"></i></div> <div show="{ticker_interval}" onclick="{pause}" class="ticker-controls_pause"><i class="fa fa-pause"></i></div> <div onclick="{backward}" class="ticker-controls_backwards"><i class="fa fa-chevron-left"></i></div> <div onclick="{forward}" class="ticker-controls_forwards"><i class="fa fa-chevron-right"></i></div> </div>', '.ticker-title { float: left; } .ticker-editor { float: left; width: 80%; display: none; } .ticker-editor.editmode { display: inherit; } .ticker-content { float: left; } .ticker-content.editmode { display: none; } .ticker-controls { float: right; } .ticker-controls .ticker-controls_play, .ticker-controls .ticker-controls_pause, .ticker-controls .ticker-controls_backwards, .ticker-controls .ticker-controls_forwards { float: left; color: #fff; background: #000; padding: 2px 4px; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; margin-left: 10px; width: 18px; font-size: 11px; }', '', function(opts) {
this.ticker_interval = null;

this.speed = (opts.speed || 3) * 1000;

this.ticker_index = 0;

this.ticksContent = [];

this.update();

this.on('mount', function() {
  var i, len, li, ref;
  ref = this.root.querySelectorAll('.ticker-editor li');
  for (i = 0, len = ref.length; i < len; i++) {
    li = ref[i];
    this.ticksContent.push(li.innerHTML);
  }
  this.ticks = this.tags.tick;
  if (!Array.isArray(this.ticks)) {
    this.ticks = [this.ticks];
  }
  this.update();
  this.tick();
  return this.play();
});

this.on('unmount', function() {
  return this.pause();
});

this.tick = (function(_this) {
  return function() {
    if (_this.ticker_index < 0) {
      _this.ticker_index = _this.ticks.length - 1;
    }
    if (_this.ticker_index >= _this.ticks.length) {
      _this.ticker_index = 0;
    }
    _this.ticks.forEach(function(tick) {
      return tick.active = false;
    });
    if (_this.ticks[_this.ticker_index] != null) {
      _this.ticks[_this.ticker_index].active = true;
    }
    _this.ticker_index += 1;
    return _this.update();
  };
})(this);

this.play = (function(_this) {
  return function() {
    if (_this.ticker_interval === null) {
      _this.ticker_interval = setInterval(_this.tick, _this.speed);
    }
    return _this.update();
  };
})(this);

this.pause = (function(_this) {
  return function() {
    if (_this.ticker_interval) {
      clearInterval(_this.ticker_interval);
    }
    _this.ticker_interval = null;
    return _this.update();
  };
})(this);

this.backward = (function(_this) {
  return function() {
    _this.pause();
    _this.ticker_index -= 2;
    return _this.tick();
  };
})(this);

this.forward = (function(_this) {
  return function() {
    _this.pause();
    return _this.tick();
  };
})(this);
});
riot.tag2('tick', '<div show="{active}" class="tick-content"><yield></yield></div>', '.tick-content { -webkit-animation-duration: 1s; -moz-animation-duration: 1s; -o-animation-duration: 1s; -ms-animation-duration: 1s; animation-duration: 1s; -webkit-animation-fill-mode: both; -moz-animation-fill-mode: both; -o-animation-fill-mode: both; -ms-animation-fill-mode: both; animation-fill-mode: both; -webkit-animation-name: ticker_fadeIn; -moz-animation-name: ticker_fadeIn; -o-animation-name: ticker_fadeIn; -ms-animation-name: ticker_fadeIn; animation-name: ticker_fadeIn; } @-moz-keyframes ticker_fadeIn { 0% { opacity: 0; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; filter: alpha(opacity=0); } 100% { opacity: 1; -ms-filter: none; filter: none; } } @-webkit-keyframes ticker_fadeIn { 0% { opacity: 0; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; filter: alpha(opacity=0); } 100% { opacity: 1; -ms-filter: none; filter: none; } } @-o-keyframes ticker_fadeIn { 0% { opacity: 0; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; filter: alpha(opacity=0); } 100% { opacity: 1; -ms-filter: none; filter: none; } } @keyframes ticker_fadeIn { 0% { opacity: 0; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; filter: alpha(opacity=0); } 100% { opacity: 1; -ms-filter: none; filter: none; } }', '', function(opts) {
this.active = opts.active;
});