window.riot = riot = require 'riot'

require 'riot-kit/lib/alert'
# require 'riot-kit/lib/eventcalendar'
require 'riot-kit/lib/i18n'
require 'riot-kit/lib/tabs'
require 'riot-kit/lib/gmap'
require 'riot-kit/lib/navigation'
require 'riot-kit/lib/dropdown'
require 'riot-kit/lib/ticker'
require 'riot-kit/lib/facebook'
require 'riot-kit/lib/twitter'
require 'riot-kit/lib/modal'
require 'riot-kit/lib/gallery'
require 'riot-kit/lib/dateformat'
require 'riot-kit/lib/datepicker'
require 'riot-kit/lib/ecommerce'
require './tags/booking-calendar' # probably need to copy this to add new functionality
require './tags/ecommercebar'
require './tags/pricetag'
require './libs/slideshow.coffee'

require './tags/eventcalendar.tag'

# Mount Root Elements
riot.mount('*')
document.addEventListener 'duocms:updated',->riot.mount('gallery')