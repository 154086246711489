
    var riot = require('riot')
    
riot.tag2('ecommercebar', '<div class="btn-group basket-group"> <button riot-tag="popup-basket" checkout-url="/system/checkout/" currency="{currency}" onclose="{basketclose}" class="btn btn-primary btn-sm basket"> Checkout : £ {parent.total || ⁗0.00⁗} </button> </div>', 'ecommercebar { display: block; margin: -7px 0 7px 0; }', '', function(opts) {
var xhr;

xhr = require("../libs/xhr.coffee");

this.on("mount", function() {
  this.currencyclass = "";
  this.currency = opts.currency || "GBP";
  this.currencies = {
    "GBP": "£",
    "USD": "$",
    "EUR": "€"
  };
  return this.getBasket();
});

this.getBasket = (function(_this) {
  return function(cback) {
    return xhr("GET", "/duocms/api/basket?currency=" + _this.currency, null, function(res) {
      _this.total = res.body.data.total.toFixed(2);
      if (cback) {
        cback();
      }
      return _this.update();
    });
  };
})(this);

this.basketclose = (function(_this) {
  return function() {
    return _this.getBasket();
  };
})(this);
});
    
  